import React, { useContext } from 'react';

import { withCart } from '../Cart/context';
import { CheckoutContext } from './context';

import { HeadingPlaceholder } from './placeholders';

import '../Form/fields/checkbox.css';

import { Regular } from '../../utils/variables';

import loadComponents from '../Loadable';

const H5 = loadComponents('h5');

export default withCart(function CheckoutShipping({ total, methods }) {
  const { checkout, setShipping } = useContext(CheckoutContext);
  const activeShipping = checkout.shipping && checkout.shipping.method_title;
  return (
    <div style={{ marginTop: `2.8125rem` }}>
      <H5
        style={{ textTransform: `uppercase` }}
        fallback={<HeadingPlaceholder />}
      >
        Shipping <span>Method</span>
      </H5>
      <div style={shippingOptions}>
        {methods.map(
          (item, i) =>
            item.methodId !== 'free_shipping' &&
            (item.minAmount === null ||
              parseFloat(total) >= parseFloat(item.minAmount)) && (
              <label
                key={item.name}
                htmlFor={`shipping${i}`}
                className={`checkbox${
                  activeShipping === item.name ? ' active' : ''
                }`}
                style={{ display: `flex` }}
              >
                <input
                  id={`shipping${i}`}
                  onChange={() =>
                    setShipping({
                      method_title: item.name,
                      method_id: item.methodId,
                      total: item.price,
                    })
                  }
                  type="checkbox"
                  aria-label={item.name}
                  style={{
                    display: `none`,
                    width: `auto`,
                  }}
                />
                <strong style={labelStyle}>
                  &pound;{parseFloat(item.price).toFixed(2)}
                </strong>
                <span
                  className="label"
                  style={{
                    ...labelStyle,
                    fontWeight: Regular,
                    marginLeft: `0.9375rem`,
                  }}
                >
                  {item.name}
                </span>
              </label>
            ),
        )}
      </div>
    </div>
  );
});

const shippingOptions = {
  display: `flex`,
  flexDirection: `column`,
};

const labelStyle = {
  fontSize: `1rem`,
  // lineHeight: 1,
};
